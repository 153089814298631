<template>
    <client-page>
        <sub-visual tit="Projects" bg="/images/sub/visual/sv--business.jpg"></sub-visual>

        <section class="section">
            <v-container>

                <div class="mb-12 mb-lg-24">
                    <v-row class="row--x-small">
                        <v-spacer></v-spacer>
                        <v-col cols="12" md="auto">
                            <v-select class="v-input--search w-100 w-md-140px" placeholder="선택" v-model="filter.searchKey" :items="searchKeys" persistent-placeholder dense outlined hide-details></v-select>
                        </v-col>
                        <v-col cols="9" md="auto">
                            <v-text-field class="v-input--search w-100 w-md-300px" v-model="filter.searchValue" :disabled="filter.searchKey == null" @keyup.enter="search" dense outlined hide-details placeholder="e.g. 'LFAB100', or '20R' or 'Supermarket'"></v-text-field>
                        </v-col>
                        <v-col cols="3" md="auto">
                            <v-btn class="v-btn--input w-100 min-w-md-100px" color="secondary" @click="search" dense><span class="white--text">Search</span></v-btn>
                        </v-col>
                    </v-row>
                </div>

                <!-- <div>
                    <v-row>
                        <v-col>
                            <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건"></v-select>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="filter.searchValue" label="검색어" :disabled="!filter.searchKey"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-btn large icon class="transparent" @click="search"><i class="icon icon-search"></i></v-btn>
                </div> -->

                <v-row>
                    <v-col v-for="board, idx in boards" :key="idx" cols="6" md="3">
                        <div style="cursor: pointer" class="h-100" @click="show(board)">
                            <card-primary>
                                <div class="thumb">
                                    <div class="thumb__inner" :style="`background-image:url(${board?.thumb});`"></div>
                                </div>
                                <div class="w-100 pa-20">
                                    <p class="page-text line-height-13 word-keep-all business__tit">
                                        {{board?.subject}}
                                    </p>
                                </div>
                            </card-primary>
                        </div>
                    </v-col>
                </v-row>

                <div class="v-pagination-wrap">
                    <v-pagination v-model="page" :length="pageCount" @input="search"/>
                </div>
            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/en/templates/ClientPage.vue";
import SubVisual from "@/components/client/en/sub/sub-visual.vue";
import CardPrimary from "@/components/dumb/card-primary.vue";
import api from "@/api";

export default {
    components: {
        ClientPage,
        SubVisual,
        CardPrimary,
    },
    data() {
        return {
            boards: [],
            files: [],
            pageCount: 0,
            limit: 8,
            filter: {
                searchKey: "subject + content",
                searchValue: this.$route.query.searchValue,
                code: "delivery",
                language: "en"
            },
            searchKeys: [
                { text: "All", value: "subject + content" },
                { text: "Title", value: "subject" },
                { text: "Contents", value: "content" },
            ],
            searchKey: null,
            searchValue: null,
        }
    },
    mounted() {
        this.search();
    },
    methods: {
        async search() {
            let { boards, summary } = await api.v1.boards.gets({ 
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter });
            this.boards = boards;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        show(board) {
            this.$router.push(`business/${board._id}`);
        },
    }
};
</script>

<style scoped>
.business__tit{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>
