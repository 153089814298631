var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-visual secondary"
  }, [_c('div', {
    staticClass: "sub-visual-tit"
  }, [_c('v-container', [_c('h2', {
    attrs: {
      "data-aos": "zoom-in"
    }
  }, [_vm._v(_vm._s(_vm.tit))]), _vm._t("default")], 2)], 1), _c('div', {
    staticClass: "sub-visual-bg",
    style: 'background-image:url(' + _vm.bg + ');',
    attrs: {
      "data-aos": "fade"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }