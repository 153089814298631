var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "tit": "Projects",
      "bg": "/images/sub/visual/sv--business.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('div', {
    staticClass: "mb-12 mb-lg-24"
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "v-input--search w-100 w-md-140px",
    attrs: {
      "placeholder": "선택",
      "items": _vm.searchKeys,
      "persistent-placeholder": "",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "9",
      "md": "auto"
    }
  }, [_c('v-text-field', {
    staticClass: "v-input--search w-100 w-md-300px",
    attrs: {
      "disabled": _vm.filter.searchKey == null,
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "placeholder": "e.g. 'LFAB100', or '20R' or 'Supermarket'"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      }
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3",
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--input w-100 min-w-md-100px",
    attrs: {
      "color": "secondary",
      "dense": ""
    },
    on: {
      "click": _vm.search
    }
  }, [_c('span', {
    staticClass: "white--text"
  }, [_vm._v("Search")])])], 1)], 1)], 1), _c('v-row', _vm._l(_vm.boards, function (board, idx) {
    return _c('v-col', {
      key: idx,
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "h-100",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.show(board);
        }
      }
    }, [_c('card-primary', [_c('div', {
      staticClass: "thumb"
    }, [_c('div', {
      staticClass: "thumb__inner",
      style: `background-image:url(${board === null || board === void 0 ? void 0 : board.thumb});`
    })]), _c('div', {
      staticClass: "w-100 pa-20"
    }, [_c('p', {
      staticClass: "page-text line-height-13 word-keep-all business__tit"
    }, [_vm._v(" " + _vm._s(board === null || board === void 0 ? void 0 : board.subject) + " ")])])])], 1)]);
  }), 1), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }